html {
    min-height: 100%;
    position: relative;
}

body {
    margin-bottom: 71px;
}

label {
    font-weight: inherit;
}

label.required {
    font-weight: bold;
}

i {
    cursor: default;
    margin: 0 1px;
}

a > i {
    cursor: pointer;
}

th {
    vertical-align: top;
}

.input-group-addon i.fa {
    width: 1em;
}

i.fa-m {
    color: rgba(255, 255, 255, 0.2);
}

i.fa-md {
    color: rgba(0, 0, 0, 0.2);
}

input[type="file"] {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: 0 none;
    color: #999999;
    height: auto;
}

.body {
    padding: 15px;
}

.login-header {
    background: none repeat scroll 0 0 #1e2024;
    padding: 50px 0;
    position: relative;
}

.login-header.login-caret:after {
    border-color: #1e2024 transparent transparent;
    border-style: solid;
    border-width: 13px 12.5px 0;
    bottom: -13px;
    content: "";
    height: 0;
    left: 50%;
    margin-left: -12.5px;
    position: absolute;
    width: 0;
}

.login-form {
    padding-top: 60px;
    position: relative;
}

.login-content {
    margin: 0 auto;
    padding: 20px 0;
    position: relative;
    text-align: center;
}

.login-form {
    padding-top: 60px;
    position: relative;
    width: 320px;
    margin: 0 auto;
}

.login-form input,
.login-form button,
.login-form .input-group-addon {
    font-size: 15px;
    height:inherit;
    padding: 10px 15px;
}

.login-form .input-group,
.login-form .btn-login,
.login-form .message {
    margin: 5px;
}

.message {
    width: 100%;
    opacity: 0.5;
}

.hero {
    font-size: 60pt;
    color: #333;
    font-family: "Open Sans",Verdana,sans-serif;
    font-weight: 700;
}

@media only screen and ( max-width: 500px ) {
    .hero { font-size: 40pt; }
    .login-form { width: 280px }
}

@media only screen and ( max-width: 320px ) {
    .hero { font-size: 30pt; }
    .login-form { width: 260px }
}

.whatsthis {
    margin-top: 150px;
    text-align: center;
}

.jumbotron {
    background-color: #444;
    margin: 20px;
    color: #eee;
    border: 1px solid #222;
    box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    line-height: 1.7;
    min-width: 572px;
}

.btn {
    border-color: rgba(0,0,0,0.3);
}

.page-header {
    margin: 10px 0;
    color: #ddd;
}

.card-default {
    margin: 10px 0;
}

.errorbubble {
    margin: 8px 4px 0;
    padding: 2px 4px;
    color: #b94a48;
    background-color: rgba(255, 239, 239, 0.7);
    border: 1px solid #b94a48;
    border-radius: 3px;
}

.header-top,
.header-top > h1,
.header-top > h2,
.header-top > h3,
.header-top > h4,
.header-top > h5 {
    margin-top: 0;
}

.record_properties {
    width: 100%;
    word-wrap: anywhere;
    overflow-wrap: anywhere;
}

.record_properties th {
    width: 30%;
    text-align: right;
    padding-right: 2em;
}

.well {
    border: 1px solid #555;
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.4);
}

.list-group-item {
    padding: 5px 10px;
}

.stealth {
    background: none;
    border: 0;
    color: #eee;
    margin: 5px;
}

.list-group-item-plain {
    background-color: rgba(0, 0, 0, 0.1);
    border: 0 0 1px 0;
    border-color: rgba(0, 0, 0, 0.3);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn,
.form-control[disabled],
fieldset[disabled] .form-control
{
    opacity: 0.2;
}

fieldset[readonly] .btn,.form-control[readonly],fieldset[readonly] .form-control
{
    opacity: 0.6;
}

.pagination > li.active > span {
    border-color: #3c3c3c;
}

.pagination > li.active > span:hover,
.pagination > li > a:hover {
    border-color: #3c3c3c;
}

.form-group {
    margin-bottom: 1px;
}

::-webkit-input-placeholder { /* WebKit browsers */
    color: #333;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #333;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #333;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #333;
}

i.fa {
    width: 1.3em;
    text-align: left;
}

.searchable {
    background-color: #595959;
    padding: 3px;
    line-height: 1.2;
    margin-bottom: 5px;
}

.searchable:hover,
.searchable.active {
    background-color: #666;
}

.imprint {
    margin: 20px auto;
    padding: 10px;
    max-width: 960px;
    font-size: 0.85em;
}
.col-form-label.required::after {
    content: " *";
    font-weight: normal;
}
