.modal-header {
    background-color: #222;
    background-image: linear-gradient(to bottom, #333 0%, #222 100%);
}

.alert.alert-success,
.alert.alert-info,
.alert.alert-danger,
.alert.alert-warning {
    border-color: #3c3c3c;
}

.navbar.navbar-dark.bg-dark {
    background-color: #222 !important;
}

.hoverrow:hover {
    background-color: rgba(255,255,255,0.1);
}

.pagination {
    margin: 15px 0;
}

.pagination li.page-item .page-link {
    font-size: smaller;
    padding: 0.25rem 0.5rem;
    background-color: #666;
    border-color: #444;
}

.pagination li.page-item.active .page-link {
    background-color: #E36B23;
    border-color: #E36B23;
}

.pagination li.page-item.disabled .page-link {
    background-color: #555;
}

.form-horizontal .form-control-static {
    padding: 5px;
}

#footer {
    bottom: 0;
    height: 60px;
    position: absolute;
    width: 100%;
    text-align: center;
    padding: 20px;
    font-size: 0.85em;
}

.cover {
    padding: 5rem;
    margin: 5rem;
    line-height: 5;
}

.custom-select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.page-header {
    padding-bottom: 9px;
    margin: 20px 0;
    border-bottom: 1px solid #555;
}
