$font-family-sans-serif:      "Open Sans";
$font-size-base:              0.85rem;

$white:    #fff;
$gray-100: #f9f9f9;
$gray-200: #ececec;
$gray-300: #e2e2e2;
$gray-400: #d4d4d4;
$gray-500: #b5b5b5;
$gray-600: #757575;
$gray-700: #505050;
$gray-800: #3a3a3a;
$gray-900: #252525;
$black:    #000;

$blue:    #297BBE;
$indigo:  #6429be;
$purple:  #7d58b4;
$pink:    #c84a85;
$red:     #C84B49;
$orange:  #DC9E47;
$yellow:  #e6d431;
$green:   #4FA04F;
$teal:    #2eb48c;
$cyan:    #51AFC8;

$primary:       $blue;
$secondary:     $gray-700;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-400;
$dark:          $gray-900;

$theme-link-color: #E36B23;
$theme-link-hover-color: #E32D15;

$enable-rounded:                              false;
$enable-shadows:                              true;
$enable-gradients:                            false;

$component-active-bg:         $theme-link-color;

$body-bg:                   #444;
$body-color:                #ccc;

$text-muted:                  #999;

$link-color:                              $theme-link-color;
$link-decoration:                         none;
$link-hover-color:                        $theme-link-hover-color;
$link-hover-decoration:                   none;

$navbar-dark-brand-color:                 #999;
$navbar-dark-brand-hover-color:           #ccc;
$navbar-dark-color:                 #999;
$navbar-dark-active-color:          #ccc;

$dropdown-bg:                       #666;
$dropdown-divider-bg:               #777;
$dropdown-link-color:               #ccc;
$dropdown-link-hover-color:         #eee;
$dropdown-link-hover-bg:            $theme-link-color;
$dropdown-link-active-color:        #fff;

$form-feedback-font-size:           1rem;

$input-bg:                              #ccc;
$input-disabled-bg:                     #bbb;
$input-color:                           #222;
$input-border-color:                    #333;

$input-group-addon-color:               #ccc;
$input-group-addon-bg:                  #777;
$custom-file-button-color:          #ccc;
$custom-file-button-bg:             #777;

$card-color:                        #ddd;
$card-bg:                           #555;
$card-border-color:                 #333;

$table-cell-padding:          .2rem;
$table-border-width:          0;
$table-border-color:          #444;

$modal-header-padding-y:            0.5rem;
$modal-header-padding-x:            1rem;
$modal-content-bg:                             #666;
$modal-content-border-color:                   #000;
$modal-header-border-color:         $modal-content-border-color;
$modal-footer-border-color:         $modal-content-border-color;

$badge-font-size:                   85%;

